import React from "react"
// import { Link } from "gatsby"
import { Helmet } from "react-helmet"

import SiniestrosLayout from "../../components/siniestros-layout"

import icoRus from "../../assets/old/img/logos-ase/ico-rus.png";

export default () => (
    <SiniestrosLayout subCompaniaActivo="rus">
        <Helmet>
            <title>Rio Uruguay Seguros - Siniestros</title>
            <meta
                name="description"
                content="Servicio de asistencia Rio Uruguay Seguros: 0810-888-7080."
            />
        </Helmet>

        <hr className="mb-4" />
        {/* Quotation */}
        <div className="card blog-card mb-2">
            <div className="card-body px-sm-5 py-5 bg-secondary">
                <img
                    className="float-md-right pb-2"
                    src={icoRus}
                    alt="Rio Uruguay Seguros"
                />
                <h4 className="lead post-title text-muted">Rio Uruguay Seguros</h4>
                <p className="">Servicio de asistencia: 0810-888-7080.</p>
            </div>
        </div>
        <p className="pt-5 pb-3">
            Tenes 72 hs para realizar la Denuncia RUS. Según el tipo de cobertura contratada, te indicamos qué documentación presentar y como proceder.
        </p>
        <h6>Colisión con otro vehículo</h6>
        <p>
          Ante un siniestro es importante que al tercero le solicites: Nombre, apellido y teléfono del conductor, titular del vehículo, compañía de seguro, N° de póliza, marca, modelo y patente del vehículo. También te sugerimos sacar fotografías del siniestro.
        </p>

        <h6>Accidente con lesiones</h6>
        <p>
          Si alguna persona sufrió lesiones como producto del accidente, te sugerimos llamar inmediatamente a emergencias médicas y/o a la policía. Solicitá los datos del afectado (nombre, apellido, DNI y teléfono) y si las lesiones fueron de consideración, no lo muevas del lugar porque podría agravarse el cuadro. Ante este tipo de situaciones te sugerimos avisar a RUS a la brevedad llamando al servicio de atención al asegurado: 0810-888-7080.
        </p>

        <h6>Robo o hurto del vehículo</h6>
        <p>
          En caso de sufrir el robo o hurto de tu vehículo, tenés que realizar la denuncia policial en la seccional más cercana al lugar donde se produjo el robo/hurto y solicitar una copia de la misma. En caso de que el vehículo contará con equipo de GNC, tenés que declarar la marca, modelo, N° regulador y tubo.
        </p>

        <hr className="pb-4" />



    </SiniestrosLayout>
)
